import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Functions
   ========================================================================== */
const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ; (result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

// sort by name in descending order
const compareName = (a, b) => {
  const reportA = a.node.title
  const reportB = b.node.title

  let comparison
  if (reportA > reportB) {
    comparison = 1
  } else if (reportA < reportB) {
    comparison = -1
  }

  return comparison
}

// sort by year in descending order
const compareElementYear = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

/* ==========================================================================
    Component
   ========================================================================== */
const Reports = ({ id, annualReports, financialReports }) => {
  let annualGroupByYear = annualReports && groupBy(annualReports, "year")
  // split annualGroupByYear to an array
  const annualArr =
    (annualGroupByYear &&
      Object.keys(annualGroupByYear).map(key => [
        key,
        annualGroupByYear[key],
      ])) ||
    []

  const financialGroupedByYear =
    (financialReports && groupBy(financialReports, "year")) || []
  // split financialGroupedByYear to an array
  let financialArr =
    (financialGroupedByYear &&
      Object.keys(financialGroupedByYear).map(key => [
        key,
        financialGroupedByYear[key],
      ])) ||
    []

  let documentItems = []

  annualArr.length > 0 &&
    annualArr.map((annualItem, i) => {
      let annualData = []
      let financialData = []
      annualItem[1].map(item => {
        annualData.push({
          title: item.node.title,
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
        return 1
      })
      const match = financialArr.find(x => x[0] === annualItem[0])
      match && match[1].sort(compareName)
      if (match) {
        match[1].map(item => {
          financialData.push({
            title: item.node.title || "Title",
            linkTo:
              item.node.acfFile.file !== null &&
              item.node.acfFile.file.mediaItemUrl,
            fileSize:
              item.node.acfFile.file !== null &&
              item.node.acfFile.file.filesize,
            fileId:
              item.node.acfFile.file != null &&
              item.node.acfFile.file.databaseId,
            date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
              item.node.date
            ).format("HH:mm")}`,
          })
          return 1
        })
      }

      documentItems.push(
        <div key={`${annualItem[0]}-${i}`} className="mb-5">
          <Document
            title={annualItem[0]}
            section="Annual Report"
            type="annual"
            data={annualData}
          />
          {financialData.length > 0 && (
            <Document section="Financial Report" data={financialData} />
          )}
        </div>
      )

      return 1
    })

  financialArr.length > 0 &&
    financialArr.map((financialItem, i) => {
      let financialData = []
      const match = annualArr.find(x => x[0] === financialItem[0])
      if (!match) {
        financialItem[1].map(item =>
          financialData.push({
            title: item.node.title || "Title",
            linkTo:
              item.node.acfFile.file !== null &&
              item.node.acfFile.file.mediaItemUrl,
            fileSize:
              item.node.acfFile.file !== null &&
              item.node.acfFile.file.filesize,
            fileId:
              item.node.acfFile.file != null &&
              item.node.acfFile.file.databaseId,
            date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
              item.node.date
            ).format("HH:mm")}`,
          })
        )
      }
      if (financialData.length > 0) {
        documentItems.push(
          <div key={`${financialItem[0]}-${i}`} className="mb-5">
            <Document
              title={financialItem[0]}
              section="Financial Report"
              data={financialData}
            />
          </div>
        )
      }

      return -1
    })

  return (
    <section className="report">
      {documentItems.length > 0 && documentItems.sort(compareElementYear)}
      {documentItems.length === 0 && <div></div>}
    </section>
  )
}

/* ==========================================================================
    Render
   ========================================================================== */

const AnnualReport = location => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "financial-reports") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        annual: fileCategory(id: "annual", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        financial: fileCategory(id: "financial", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const financialGroupedByCompany =
    data.wordPress.financial &&
    groupBy(data.wordPress.financial.filesPdf.edges, "company")

  return (
    <Layout lang="ID" location={location} currentLocation={location.uri}>
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                      "The Investor Relations Section is dedicated for shareholders and investors. Here you will find updated annual report from Sinarmas Limited, PT. Bumi Serpong Damai Tbk, and PT. Duta Pertiwi Tbk",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}

      <Container>
        <NavTabs
          scrollable
          className="pt-4"
          data={[
            {
              name: "Sinarmas Land Limited",
              target: "sinarmasland",
            },
            { name: "PT Bumi Serpong Damai Tbk", target: "bsd" },
            {
              name: "PT Duta Pertiwi Tbk",
              target: "dutaPertiwi",
            },
          ]}
        />
      </Container>

      {financialGroupedByCompany && (
        <>
          <TabPane id="sinarmasland" className="py-lg-5 pt-5 pt-lg-0 active">
            <Reports
              financialReports={
                financialGroupedByCompany &&
                financialGroupedByCompany["Sinarmas Land Limited"] !==
                undefined &&
                financialGroupedByCompany["Sinarmas Land Limited"]
              }
            />
          </TabPane>
          <TabPane id="bsd" className="py-lg-5 pt-5 pt-lg-0">
            <Reports
              id="bsd"
              financialReports={
                financialGroupedByCompany &&
                financialGroupedByCompany["PT Bumi Serpong Damai Tbk"]
              }
            />
          </TabPane>
          <TabPane id="dutaPertiwi" className="py-lg-5 pt-5 pt-lg-0">
            <Reports
              financialReports={
                financialGroupedByCompany &&
                financialGroupedByCompany["PT Duta Pertiwi Tbk"]
              }
              id="dutaPertiwi"
            />
          </TabPane>
        </>
      )}
    </Layout>
  )
}

export default AnnualReport
